import React, { useEffect, useState } from 'react'
import Spinwheel from '../Spinwheel'
import { cityByCountryApi, countryListApi, defaultCategoryListOptionsApi } from '../../../utils/ApiEndpointFunctions';
import ReactSelect from 'react-select';
import DatePicker from "react-datepicker";

function RewardAndReferralModal(props) {
  const { formik, addLoader, modalId,type } = props;  

  const [countryOptions, setCountryOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [defaultCategoryOptions, setDefaultCategoryOptions] = useState([])

  const filterCites = async (countryId) => {
    try {
      const res = await cityByCountryApi("", countryId)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCityOptions(cityOption);
      }

    } catch (error) {
      console.log(error)
    }
  };

  const filterCountry = async (inputValue) => {
    try {
      const res = await countryListApi(inputValue)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCountryOptions(cityOption);
      }
    } catch (error) {
      console.log(error)
    }
  };


  const handleCountryChange = (country) => {
   
    setCityOptions([])
    formik.setFieldValue('countryId', country);
    if(!formik?.values?.id){
       formik.setFieldValue('cityId', []);
    }
   //  formik.setFieldValue('category', []);
    filterCites(country.value)
  }

  

  const handleCityChange = (city) => {
      formik.setFieldValue('cityId',city)
  }

  const handleCategoryChange = (category) => {
      formik.setFieldValue('category', [...category])
  }



  useEffect(() => {
    filterCountry()
  },[])

  const referralDriverFeilds = [
    { key: "minTrips", label: 'Min trip', type: "text"},
    { key: "minReferral", label: 'Min referral', type: "text" },
    { key: "minTripCharges", label: 'Min trip charges', type: "text" },
    { key: "referralPrice", label: 'Reward price', type: "text" },
    { key: "validDays", label: 'Valid Days after Signup', type: "text" },
    { key: "validRewardDays", label: 'Valid Reward Days', type: "text" },
    { key: "referralOnOff", label: 'On/off', type: "checkbox"}
  ]
  const signupDriverFeilds = [
    { key: "firstNoOf", label: 'First no. of', type: "text" },
    { key: "signupMinTrips", label: 'Min trip', type: "text" },
    { key: "signupMinTripCharges", label: 'Min trip charges', type: "text" },
    { key: "signupRewardPrice", label: 'Reward price', type: "text" },
    { key: "signupValidDays", label: 'Valid Days after Signup', type: "text" },
    { key: "signupValidRewardDays", label: 'Valid Reward Days', type: "text" },
    { key: "from", label: 'From', type: "date", max: "to" },
    { key: "to", label: 'To', type: "date", min: "from" },
    { key: "signupOnOff", label: 'On/off', type: "checkbox" }
  ]
  
  const referralRiderFeilds = [
    { key: "minTripsRider", label: 'Min trip', type: "text" },
    { key: "minReferralRider", label: 'Min referral', type: "text" },
    { key: "minTripChargesRider", label: 'Min trip charges', type: "text" },
    { key: "referralPriceRider", label: 'Reward price', type: "text" },
    { key: "validDaysRider", label: 'Valid Days after Signup', type: "text" },
    { key: "validRewardDaysRider", label: 'Valid Reward Days', type: "text" },
    { key: "referralOnOffRider", label: 'On/off', type: "checkbox" }
  ]
  const signupRiderFeilds = [
    { key: "firstNoOfRider", label: 'First no. of', type: "text" },
    { key: "signupMinTripsRider", label: 'Min trip', type: "text" },
    { key: "signupMinTripChargesRider", label: 'Min trip charges', type: "text" },
    { key: "signupRewardPriceRider", label: 'Reward price', type: "text" },
    { key: "signupValidDaysRider", label: 'Valid Days after Signup', type: "text" },
    { key: "signupValidRewardDaysRider", label: 'Valid Reward Days', type: "text" },
    { key: "fromRider", label: 'From', type: "date", max: "toRider" },
    { key: "toRider", label: 'To', type: "date", min: "fromRider" },
    { key: "signupOnOffRider", label: 'On/off', type: "checkbox" }
  ]

  // console.log(formik.errors, formik.values)

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                id="confirmModalCloseBtn"
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pe-0 ps-0">
              <div className="container">
                <h2>{formik?.values?.id ? "Update" : "Add"} </h2>
                <form onSubmit={formik?.handleSubmit} >
                  {!formik?.values?.id && <>

                    <div className='row row-cols-2'>
                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">Country</label>
                      <ReactSelect options={countryOptions} value={formik?.values?.countryId} onChange={handleCountryChange} onBlur={formik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
                      {formik?.touched?.countryId && formik?.errors?.countryId ? (<div className='req-error'>{formik?.errors?.countryId}</div>) : null}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">City</label>
                      <ReactSelect options={cityOptions} isDisabled={!formik?.values?.countryId} value={formik?.values?.cityId} onChange={handleCityChange} onBlur={formik?.handleBlur} noOptionsMessage={() => 'No Cities Available'} />
                      {formik?.touched?.cityId && formik?.errors?.cityId ? (<div className='req-error'>{formik?.errors?.cityId}</div>) : null}
                    </div>
                    </div>
                  </>}

                  <hr />
                  <h2> Driver setting</h2>
                  <hr />
                  
                  <h4>Referral</h4>
                  <hr />
                  <div className='row row-cols-4'>

                    {
                      referralDriverFeilds.map(ele => {
                        if (ele.type === "text") {
                          return <div className='d-flex flex-column mb-3'>
                            <label htmlFor={ele.key} className=" col-form-label">{ele.label}</label>
                            <div className="">
                              <input type={ele.type} className="form-control" id={ele.key} name={ele.key} placeholder="0" onChange={formik?.handleChange} onBlur={formik?.handleBlur} value={formik?.values?.[ele.key]} />
                              {formik?.touched?.[ele.key] && formik.errors?.[ele.key] ? (<div className='req-error'>{formik?.errors?.[ele.key]}</div>) : null}
                            </div>
                          </div>
                        } else if (ele.type === "checkbox") {
                          return <div className='d-flex flex-column mb-3'>
                            <label htmlFor={ele.key} className=" col-form-label">{ele.label}</label>
                            <div className="form-check form-switch">
                              <input className="form-check-input" type={ele.type} role="switch" checked={formik?.values?.[ele.key]} id={ele.key} onChange={() => formik?.setFieldValue(ele.key, !Boolean(formik?.values?.[ele.key]))} />
                              {formik?.touched?.[ele.key] && formik.errors?.[ele.key] ? (<div className='req-error'>{formik?.errors?.[ele.key]}</div>) : null}
                            </div>
                          </div>
                        } 
                      })
                    }
                  </div>                  
                  <h4>Signup reward</h4>
                  <hr />
                  <div className='row row-cols-4'>
                    {
                      signupDriverFeilds.map(ele => {
                        if (ele.type === "text") {
                          return <div className='d-flex flex-column mb-3'>
                            <label htmlFor={ele.key} className=" col-form-label">{ele.label}</label>
                            <div className="">
                              <input type={ele.type} className="form-control" id={ele.key} name={ele.key} placeholder="0" onChange={formik?.handleChange} onBlur={formik?.handleBlur} value={formik?.values?.[ele.key]} />
                              {formik?.touched?.[ele.key] && formik.errors?.[ele.key] ? (<div className='req-error'>{formik?.errors?.[ele.key]}</div>) : null}
                            </div>
                          </div>
                        } else if(ele.type === "checkbox") {
                          return <div className='d-flex flex-column mb-3'>
                            <label htmlFor={ele.key} className=" col-form-label">{ele.label}</label>
                            <div className="form-check form-switch">
                              <input className="form-check-input" type={ele.type} role="switch" checked={formik?.values?.[ele.key]} id={ele.key} onChange={() => formik?.setFieldValue(ele.key, !Boolean(formik?.values?.[ele.key]))} />
                              {formik?.touched?.[ele.key] && formik.errors?.[ele.key] ? (<div className='req-error'>{formik?.errors?.[ele.key]}</div>) : null}
                            </div>
                          </div>
                        } else if(ele.type ==="date") {
                          return <div className='d-flex flex-column mb-3'>
                            <label htmlFor={ele.key} className=" col-form-label">{ele.label}</label>
                            <div className="">
                              <DatePicker
                                className='form-control'
                                name={ele.key}
                                dateFormat={"dd/MM/yyyy"}
                                placeholderText='dd/MM/yyyy'
                                selected={formik.values?.[ele.key] ? new Date(formik.values?.[ele.key]) : null}
                                onChange={(date) => { formik.setFieldValue(ele.key, date) }}
                                maxDate={ele.max && formik.values?.[ele.max] ? new Date(formik.values[ele.max]) : undefined}
                                minDate={ele.min && formik.values?.[ele.min] ? new Date(formik.values[ele.min]) : undefined}
                              />                             
                              {formik?.touched?.[ele.key] && formik.errors?.[ele.key] ? (<div className='req-error'>{formik?.errors?.[ele.key]}</div>) : null}
                            </div>
                          </div>
                        }
                      })
                    }                  
                  </div>
                  <hr />
                  <h2>Rider setting</h2>
                  <hr />
                  <h4>Referral</h4>
                  <hr />
                  <div className='row row-cols-4'>
                    {
                      referralRiderFeilds.map(ele => {
                        if (ele.type === "text") {
                          return <div className='d-flex flex-column mb-3'>
                            <label htmlFor={ele.key} className=" col-form-label">{ele.label}</label>
                            <div className="">
                              <input type={ele.type} className="form-control" id={ele.key} name={ele.key} placeholder="0" onChange={formik?.handleChange} onBlur={formik?.handleBlur} value={formik?.values?.[ele.key]} />
                              {formik?.touched?.[ele.key] && formik.errors?.[ele.key] ? (<div className='req-error'>{formik?.errors?.[ele.key]}</div>) : null}
                            </div>
                          </div>
                        } else if (ele.type === "checkbox") {
                          return <div className='d-flex flex-column mb-3'>
                            <label htmlFor={ele.key} className=" col-form-label">{ele.label}</label>
                            <div className="form-check form-switch">
                              <input className="form-check-input" type={ele.type} role="switch" checked={formik?.values?.[ele.key]} id={ele.key} onChange={() => formik?.setFieldValue(ele.key, !Boolean(formik?.values?.[ele.key]))} />
                              {formik?.touched?.[ele.key] && formik.errors?.[ele.key] ? (<div className='req-error'>{formik?.errors?.[ele.key]}</div>) : null}
                            </div>
                          </div>
                        } 
                      })
                    }                    
                  </div>

                  <h4>Signup reward</h4>
                  <hr />
                  <div className='row row-cols-4'>
                    {
                      signupRiderFeilds.map(ele => {
                        if (ele.type === "text") {
                          return <div className='d-flex flex-column mb-3'>
                            <label htmlFor={ele.key} className=" col-form-label">{ele.label}</label>
                            <div className="">
                              <input type={ele.type} className="form-control" id={ele.key} name={ele.key} placeholder="0" onChange={formik?.handleChange} onBlur={formik?.handleBlur} value={formik?.values?.[ele.key]} />
                              {formik?.touched?.[ele.key] && formik.errors?.[ele.key] ? (<div className='req-error'>{formik?.errors?.[ele.key]}</div>) : null}
                            </div>
                          </div>
                        } else if (ele.type === "checkbox") {
                          return <div className='d-flex flex-column mb-3'>
                            <label htmlFor={ele.key} className=" col-form-label">{ele.label}</label>
                            <div className="form-check form-switch">
                              <input className="form-check-input" type={ele.type} role="switch" checked={formik?.values?.[ele.key]} id={ele.key} onChange={() => formik?.setFieldValue(ele.key, !Boolean(formik?.values?.[ele.key]))} />
                              {formik?.touched?.[ele.key] && formik.errors?.[ele.key] ? (<div className='req-error'>{formik?.errors?.[ele.key]}</div>) : null}
                            </div>
                          </div>
                        } else if (ele.type === "date") {
                          return <div className='d-flex flex-column mb-3'>
                            <label htmlFor={ele.key} className=" col-form-label">{ele.label}</label>
                            <div className="">
                              <DatePicker
                                className='form-control'
                                name={ele.key}
                                dateFormat={"dd/MM/yyyy"}
                                placeholderText='dd/MM/yyyy'
                                selected={formik.values?.[ele.key] ? new Date(formik.values?.[ele.key]) : null}
                                onChange={(date) => { formik.setFieldValue(ele.key, date) }}
                                maxDate={ele.max && formik.values?.[ele.max]  ?  new Date(formik.values[ele.max]) : undefined}
                                minDate={ele.min && formik.values?.[ele.min] ? new Date(formik.values[ele.min]) : undefined}
                              />
                              {formik?.touched?.[ele.key] && formik.errors?.[ele.key] ? (<div className='req-error'>{formik?.errors?.[ele.key]}</div>) : null}
                            </div>
                          </div>
                        }
                      })
                    }
                  </div>
                  <button type="submit" disabled={addLoader} className="btn btn-primary">
                    {addLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}
                  </button>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default RewardAndReferralModal